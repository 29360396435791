import * as Sentry from '@sentry/nextjs';

const ignoreErrors = [
  /AbortError: The play\(\) request was interrupted/,
  /AbortError: The operation was aborted/,
  /NotAllowedError: play\(\) failed/,
  /NotAllowedError: The request is not allowed/,
  /NotAllowedError: The play method is not allowed/,
  /NotSupportedError: The operation is not supported/,
  /Cannot perform Construct on a detached ArrayBuffer/,
];

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENV_NAME,
    enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLE === 'true',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    beforeSend(event, hint) {
      if (event && event.message && ignoreErrors.some((pattern) => pattern.test(event.message))) {
        // Don't send the event to Sentry
        return null;
      }
      return event;
    },
    ignoreErrors,
  });
};
