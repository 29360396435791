import dayjs from 'dayjs';
import { Record } from 'immutable';
import { QuizSubmission } from 'src/types/enrollment';

import Actions from './actions';
import { ILearningCourseState, QUIZ_STATE } from './interface';

import {
  AssessmentType,
  AssignmentStatus,
  CourseItemContentType,
  IDiscussion,
  IQuiz,
  IQuizMetadata,
  LearningPageCourseItemStatus,
} from '@interfaces/learning';

const initState = new (Record<ILearningCourseState>({
  learning_course: null,
  learning_course_pending: true,
  learning_course_error: false,
  learning_course_status: null,
  quiz_submissions: null,
  quiz_submissions_pending: false,
  quiz_state: QUIZ_STATE.START,
  is_loading_start_quiz: false,
  is_start_quiz_error: false,
  start_quiz_error: null,
  is_loading_quiz_summary_score: false,
  quiz_summary_score: null,
  is_sending_finished_log: false,
  is_retest: false,
  quiz_submission: null,
  create_certificate_pending: false,
  create_certificate_error: false,
  create_certificate_success: false,
  reviews: {
    list: {
      items: [],
      count: 0,
      hasMore: false,
    },
    summary: {
      averageRating: 0,
      count: 0,
      ratings: [],
    },
  },
  discussions: {
    items: [],
    count: 0,
    hasMore: false,
  },
  limit_discussion: 20,
  fetch_review_pending: false,
  create_or_update_review_pending: false,
  is_create_or_update_review_error: false,
  fetch_review_summary_pending: false,
  fetch_discussion_pending: false,
  create_discussion_pending: false,
  is_create_discussion_error: false,
  is_discussion_reply_pending: false,
  is_create_discussion_reply_error: false,
  get_certificate_progression_error: false,
  get_certificate_progression_pending: false,
  certificateProgress: {
    certificateBundleProgress: [],
    completedCertificate: [],
  },
  update_learning_path_progression_error: false,
  update_learning_path_progression_pending: false,
  update_learning_path_progression_success: false,
}))();

export default function reducer(state = initState, { type, data, status }: any) {
  switch (type) {
    case Actions.FETCH_LEARNING_COURSE_REQUEST:
      return state.set('learning_course_pending', true);
    case Actions.FETCH_LEARNING_COURSE_SUCCESS:
      return state
        .set('learning_course', data)
        .set('learning_course_status', status)
        .set('learning_course_pending', false);
    case Actions.FETCH_LEARNING_COURSE_ERROR:
      return state
        .set('learning_course', data)
        .set('learning_course_status', status)
        .set('learning_course_pending', false)
        .set('learning_course_error', true);
    case Actions.SET_USER_ANSWERS_REQUEST:
      return state.set('quiz_submissions', null).set('quiz_submissions_pending', true);
    case Actions.SET_USER_ANSWERS_SUCCESS:
      return state
        .set('learning_course', {
          ...state.learning_course,
          course: {
            ...state.learning_course!.course!,
            certificate_criteria: data.certificateCriteria && {
              ...(data.certificateCriteria
                ? data.certificateCriteria
                : state.learning_course?.course?.certificate_criteria),
            },
          },
        })
        .set('quiz_submissions', { ...state.quiz_submissions, totalScore: data.totalScore })
        .set('quiz_submissions_pending', false);
    case Actions.SET_USER_ANSWERS_ERROR:
      return state.set('quiz_submissions_pending', false);
    case Actions.FETCH_QUIZ_SUMMARY_SCORE_REQUEST:
      return state.set('is_loading_quiz_summary_score', true);
    case Actions.FETCH_QUIZ_SUMMARY_SCORE_SUCCESS:
      return state.set('is_loading_quiz_summary_score', false).set('quiz_summary_score', data);
    case Actions.FETCH_QUIZ_SUMMARY_SCORE_ERROR:
      return state.set('is_loading_quiz_summary_score', false);
    case Actions.CLEAR_QUIZ_SUMMARY_SCORE:
      return state.set('quiz_summary_score', null).set('quiz_submissions', null);
    case Actions.SET_RETEST_STATE:
      return state.set('is_retest', true);
    case Actions.CLAER_RETEST_STATE:
      return state.set('is_retest', false);
    case Actions.GET_QUIZ_SUBMISSION_REQUEST:
      return state.set('quiz_submission', null);
    case Actions.GET_QUIZ_SUBMISSION_SUCCESS:
      return state.set('quiz_submission', data);
    case Actions.GET_QUIZ_SUBMISSION_ERROR:
      return state.set('quiz_submission', null);
    case Actions.CREATE_OR_UPDATE_FINISHED_LOG_REQUEST:
      return state
        .set('learning_course', {
          ...state.learning_course,
          parts: state.learning_course?.parts?.map((part) => {
            const targetIndex = part.courseItems.findIndex((courseItem) => courseItem.id === data.courseItemId);
            if (targetIndex !== -1) {
              const modifiedCourseItems = [...part.courseItems];
              const targetCourseItem = modifiedCourseItems[targetIndex];

              let modifiedMetadata: IQuizMetadata | null = null;

              // If assignment set assignmentExpiredAt
              if (
                data.courseItemStatus === LearningPageCourseItemStatus.PROGRESS &&
                targetCourseItem.contentType === CourseItemContentType.QUIZ &&
                (targetCourseItem.metadata as IQuizMetadata).assessmentType === AssessmentType.ASSIGNMENT
              ) {
                const quizContent = state.get('learning_course')?.content as IQuiz;

                if (!!quizContent && !!quizContent.time_limit_day) {
                  modifiedMetadata = {
                    assignmentStatus: AssignmentStatus.STAY_IN_TIME,
                    assignmentExpiredAt: new Date(
                      dayjs()
                        .endOf('day')
                        .add(quizContent.time_limit_day, 'day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    ),
                    assessmentType: AssessmentType.ASSIGNMENT,
                  };
                }
              }

              const isLearningCompleted =
                modifiedCourseItems[targetIndex].status === LearningPageCourseItemStatus.COMPLETED;

              modifiedCourseItems[targetIndex] = {
                ...modifiedCourseItems[targetIndex],
                status: isLearningCompleted ? LearningPageCourseItemStatus.COMPLETED : data.courseItemStatus,
                metadata: modifiedMetadata || targetCourseItem?.metadata,
              };

              return { ...part, courseItems: modifiedCourseItems };
            }

            return part;
          }),
        })
        .set('is_sending_finished_log', true);
    case Actions.CREATE_OR_UPDATE_FINISHED_LOG_SUCCESS:
      return state
        .set('learning_course', {
          ...state.learning_course,
          course: {
            ...state.learning_course!.course!,
            certificate_criteria: data.certificateCriteria,
            learningProgress: data.learningProgress,
          },
        })
        .set('is_sending_finished_log', false);
    case Actions.CREATE_OR_UPDATE_FINISHED_LOG_ERROR:
      return state.set('is_sending_finished_log', false);
    case Actions.GET_CERTIFICATE_PROGRESSION_REQUEST:
      return state.set('get_certificate_progression_pending', true).set('get_certificate_progression_error', false);
    case Actions.GET_CERTIFICATE_PROGRESSION_SUCCESS:
      if (!state.learning_course?.course) return state;
      return state
        .set('certificateProgress', {
          certificateBundleProgress: data.certificateBundleProgress,
          completedCertificate: data.completedCertificate,
        })
        .set('learning_course', {
          ...state.learning_course,
          course: {
            ...state.learning_course!.course!,
            certificate_criteria: {
              ...state.learning_course.course.certificate_criteria,
              isUpdateLearningPath: true,
            },
          },
        })
        .set('get_certificate_progression_pending', false);
    case Actions.GET_CERTIFICATE_PROGRESSION_ERROR:
      return state.set('get_certificate_progression_error', true).set('get_certificate_progression_pending', false);
    case Actions.CLEAR_GET_CERTIFICATE_PROGRESSION_ERROR:
      return state.set('get_certificate_progression_error', false);
    case Actions.CREATE_CERTIFICATE_REQUEST:
      return state
        .set('create_certificate_pending', true)
        .set('create_certificate_error', false)
        .set('create_certificate_success', false);
    case Actions.CREATE_CERTIFICATE_SUCCESS:
      const learningCourse = state.learning_course;
      if (!learningCourse?.course) return state;
      return state
        .set('learning_course', {
          ...learningCourse,
          course: {
            ...learningCourse.course,
            certificate_criteria: {
              ...learningCourse.course.certificate_criteria,
              certificate: data.certificate,
            },
          },
        })
        .set('create_certificate_pending', false)
        .set('create_certificate_success', true);
    case Actions.CREATE_CERTIFICATE_ERROR:
      return state
        .set('create_certificate_error', true)
        .set('create_certificate_pending', false)
        .set('create_certificate_success', false);
    case Actions.START_QUIZ_REQUEST:
      return state
        .set('is_loading_start_quiz', true)
        .set('is_start_quiz_error', false)
        .set('start_quiz_error', null);
    case Actions.START_QUIZ_REQUEST_SUCCESS:
      const updateLearningCourse: ILearningCourseState['learning_course'] = {
        ...state.learning_course,
        content: {
          ...state.learning_course?.content,
          quizSubmission: data as QuizSubmission,
        } as IQuiz,
      };
      return state
        .set('is_loading_start_quiz', false)
        .set('learning_course', updateLearningCourse)
        .set('is_start_quiz_error', false)
        .set('start_quiz_error', null)
        .set('is_retest', false);
    case Actions.START_QUIZ_REQUEST_ERROR:
      return state
        .set('is_loading_start_quiz', false)
        .set('is_start_quiz_error', true)
        .set('start_quiz_error', data);
    case Actions.CLEAR_CREATE_CERTIFICATE_ERROR:
      return state.set('create_certificate_error', false);
    case Actions.FETCH_REVIEWS_REQUEST:
      return state.set('fetch_review_pending', true);
    case Actions.FETCH_REVIEWS_SUCCESS:
      return state
        .set('reviews', {
          ...state.reviews,
          list: {
            items: data.reviews,
            hasMore: data.hasMore,
            count: data.count,
          },
        })
        .set('fetch_review_pending', false);
    case Actions.FETCH_REVIEWS_ERROR:
      return state.set('fetch_review_pending', false);
    case Actions.CREATE_OR_UPDATE_REVIEW_REQUEST:
      return state.set('is_create_or_update_review_error', false).set('create_or_update_review_pending', true);
    case Actions.CREATE_OR_UPDATE_REVIEW_SUCCESS:
      if (!state.learning_course?.course) return state;

      return state
        .set('reviews', {
          ...state.reviews,
          list: {
            ...state.reviews.list,
            items: [data.review].concat(state.reviews.list.items.filter((item) => item.userId !== data.review.userId)),
            count: data.summary.count,
          },
          summary: data.summary,
        })
        .set('learning_course', {
          ...state.learning_course,
          course: {
            ...state.learning_course.course,
            isReviewed: true,
          },
        })
        .set('create_or_update_review_pending', false);
    case Actions.CREATE_OR_UPDATE_REVIEW_ERROR:
      return state.set('is_create_or_update_review_error', true).set('create_or_update_review_pending', false);
    case Actions.FETCH_REVIEWS_SUMMARY_REQUEST:
      return state.set('fetch_review_summary_pending', true);
    case Actions.FETCH_REVIEWS_SUMMARY_SUCCESS:
      return state
        .set('reviews', {
          ...state.reviews,
          summary: data,
        })
        .set('fetch_review_summary_pending', false);
    case Actions.FETCH_REVIEWS_SUMMARY_ERROR:
      return state.set('fetch_review_summary_pending', false);
    case Actions.UPDATE_REVIEW_SKIP_LOG_SUCCESS:
      if (!state.learning_course?.course) return state;
      return state.set('learning_course', {
        ...state.learning_course,
        course: {
          ...state.learning_course.course,
          reviewSkip: {
            skipTimes: state.learning_course.course?.reviewSkip?.skipTimes + 1,
            latestSkipAt: new Date(),
          },
        },
      });
    case Actions.FETCH_DISCUSSION_REQUEST:
      return state.set('fetch_review_pending', true);
    case Actions.FETCH_DISCUSSION_SUCCESS:
      return state
        .set('discussions', {
          items: data.items,
          hasMore: data.hasMore,
          count: data.count,
        })
        .set('fetch_review_pending', false);
    case Actions.FETCH_DISCUSSION_ERROR:
      return state.set('fetch_review_pending', false);
    case Actions.CREATE_DISCUSSION_REQUEST:
      return state.set('create_discussion_pending', true).set('is_create_discussion_error', false);
    case Actions.CREATE_DISCUSSION_SUCCESS:
      return state.set('create_discussion_pending', false).set('discussions', {
        ...state.discussions,
        items: [
          {
            ...data,
            discussion_replies: [],
          },
        ].concat(state.discussions.items),
      });
    case Actions.CREATE_DISCUSSION_ERROR:
      return state.set('create_discussion_pending', false).set('is_create_discussion_reply_error', true);
    case Actions.CREATE_DISCUSSION_REPLY_REQUEST:
      return state.set('is_discussion_reply_pending', true).set('is_create_discussion_reply_error', false);
    case Actions.CREATE_DISCUSSION_REPLY_SUCCESS:
      return state.set('is_discussion_reply_pending', false).set('is_create_discussion_reply_error', false);
    case Actions.CREATE_DISCUSSION_REPLY_ERROR:
      return state.set('is_discussion_reply_pending', false).set('is_create_discussion_reply_error', true);

    case Actions.UPDATE_LEARNING_PATH_PROGRESSION_REQUEST:
      return state
        .set('update_learning_path_progression_pending', true)
        .set('update_learning_path_progression_error', false);
    case Actions.UPDATE_LEARNING_PATH_PROGRESSION_SUCCESS:
      return state
        .set('update_learning_path_progression_pending', false)
        .set('update_learning_path_progression_success', true);
    case Actions.UPDATE_LEARNING_PATH_PROGRESSION_ERROR:
      return state
        .set('update_learning_path_progression_error', true)
        .set('update_learning_path_progression_pending', false);
    case Actions.CLEAR_UPDATE_LEARNING_PATH_PROGRESSION_ERROR:
      return state
        .set('update_learning_path_progression_error', false)
        .set('update_learning_path_progression_success', false);

    default:
      return state;
  }
}
